/* REVIEW: ibocan global olarak Multiselect Dark Theme Sıkıntısı Giderildi */

.mso.dark .dropdown-container {
  display: inline-block;
  background-color: transparent;
  width: 100%;
}
.mso.dark .dropdown-container .dropdown-content .panel-content {
  display: inline-block;
  background-color: transparent;
  width: 100%;
}
.mso.dark .dropdown-container .dropdown-content .panel-content {
  display: inline-block;
  background-color: #363636;
  width: 100%;
}
.mso.dark
  .dropdown-container
  .dropdown-content
  .panel-content
  .select-item.selected {
  background: #575454;
}
.mso.dark
  .dropdown-container
  .dropdown-content
  .panel-content
  .select-item:hover {
  background: #636262;
}
.mso.dark .dropdown-container input[type="text"] {
  display: inline-block;
  background-color: transparent;
  width: 100%;
}
.mso .dropdown-container:focus-within {
  box-shadow: none;
  border-color: transparent;
}
